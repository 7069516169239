// *******REDIRECT AUTH URL********************
const devRedirectAuthUrl = "https://neuronah2o.com"
const prodRedirectAuthUrl = "https://simplifiedsystemprocess.com"

export const baseAuthUrl = prodRedirectAuthUrl

// *********BACKEND API URL****************
const ngrokBackEndApi = "https://76e9-104-6-74-98.ngrok-free.app"
const devBackEndApi = "https://formsapi-image-827869938057.us-east1.run.app"
const prodBackEndApi = "https://spsformsapi-1014429694649.us-east1.run.app"


export const billingApiUrl = prodBackEndApi