import {BrowserRouter, Route, Routes} from "react-router-dom";
import PatientInformation from "./Pages/PatientInformation";
import InvoiceDetails from "./Pages/InvoiceDetails";
import PatientId from "./Pages/PatientId";
import Contract from "./Pages/Contract";
import Confirmation from "./Pages/Confirmation";
import {Provider} from "react-redux";
import Error404 from "./Pages/Error404";
import Error401 from "./Pages/Error401";
import {store} from "./store/store";
import Error401LinkExpired from "./Pages/Error401LinkExpired";
import Error401LinkCancelled from "./Pages/Error401LinkCancelled";
import Error401StepsComplete from "./Pages/Error401StepsComplete";
import {useAuthState} from 'react-firebase-hooks/auth'
import {auth} from "./FirebaseConfig";
import AuthSmsPage from "./Pages/AuthSmsPage";

import {persistStore} from "redux-persist";
import {PersistGate} from "redux-persist/integration/react";
import UserProvider from "./Types/UserContext";

import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles/App.css";
import ProtectedRoute from "./Pages/ProtectedRoute";
import Error403 from "./Pages/Error403";
import {useEffect} from "react";
import i18n from "./i18n";


// export const UserLinkIdStatus = createContext<any>(null);

function App() {

    // const [userLinkId, setUserLinkId] = useState<any>("");
    useEffect(() => {
        const lng = navigator.language;
        i18n.changeLanguage(lng);
    })

    let persistor = persistStore(store);
    const [user] = useAuthState(auth);


    const redirectLink = window.location.href;
    const queryParams = new URLSearchParams(window.location.search);
    const linkId: any = queryParams.get("id");
    const linkTypeId: any = queryParams.get("t");

    console.log("THIS IS THE LINK IN APP", window.location.href);

    //HTTPS=true -> for using https
    return (
        <>
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                    {/* <UserLinkIdStatus.Provider value={{ userLinkId, setUserLinkId }}> */}
                    <UserProvider>
                        <BrowserRouter>
                            <Routes>
                                {/* <Route path="/" element={<Landing/>}/> This route will be the security gateway page*/}

                                {/* <Route path="/auth" element={<AuthPage id={linkId} link={redirectLink}/>}></Route> */}
                                <Route path="/auth" element={<AuthSmsPage id={linkId} link={redirectLink} linkType={linkTypeId}/>}></Route>
                                <Route path={`/patientinfo`} element={
                                    <ProtectedRoute><PatientInformation link={redirectLink}
                                                                        persistor={persistor}/></ProtectedRoute>}/>
                                <Route path="/patientinvoicedetail" element={<InvoiceDetails/>}/>
                                <Route path="/patientiddocs" element={<PatientId/>}/>
                                <Route path="/patientcontract" element={<Contract/>}/>
                                <Route path="/confirmation" element={<Confirmation/>}/>
                                <Route path="/notauthorized" element={<Error401/>}/>
                                <Route path="/forbidden" element={<Error403/>}/>
                                <Route path="/stepscomplete" element={<Error401StepsComplete/>}/>
                                <Route path="/linkexpired" element={<Error401LinkExpired/>}/>
                                <Route path="/linkcancelled" element={<Error401LinkCancelled/>}/>
                                <Route path="*" element={<Error404/>}/>
                            </Routes>
                        </BrowserRouter>
                    </UserProvider>
                    {/* </UserLinkIdStatus.Provider> */}
                </PersistGate>
            </Provider>
        </>
    );
}

export default App;
