import {Alert, Button, Container, Form} from "react-bootstrap";
import "../Styles/AuthPage.css"
import {useLocation, useNavigate} from "react-router-dom";
import {useContext, useEffect, useRef, useState} from "react";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from '../FirebaseConfig'
import {RecaptchaVerifier, signInWithPhoneNumber} from "firebase/auth";
import {billingApiUrl} from "../Components/APIs/urls";
import {useAppDispatch, useAppSelector} from "../store/store";
import Error401 from "./Error401";
import {UserContext} from "../Types/UserContext";
import Cookies from "universal-cookie";
import {useTranslation} from "react-i18next";


export default function AuthSmsPage({link}: any, {linkType}: any) {


    const API_KEY = process.env.REACT_APP_API_KEY + "";
    const patientObj: any = useAppSelector((state) => state.patient.patientObj);
    const linkObj: any = useAppSelector((state) => state.link.linkObj);
    const authObj: any = useAppSelector((state) => state.auth.authObj);
    console.log("This is the patient", patientObj)

    const continiueUrl = window.location.href

    console.log("THIS IS THE LINK IN AUTH CONTINUEURL", continiueUrl);


    const location = useLocation();
    const {search} = location;

    const dispatch = useAppDispatch();

    const {setUserLinkId, userLinkId} = useContext(UserContext)

    const cookies = new Cookies();

    const {t, i18n} = useTranslation();
    const lng = navigator.language;

    const [email, setEmail] = useState("");

    const [phone, setPhone] = useState<any>("");
    const [userInfo, setUserInfo] = useState<any>(null);
    const [otp, setOtp] = useState<any>();
    const [smsError, setSmsError] = useState();

    const [error, setError] = useState("");
    const [loginError, setLoginError] = useState("");
    const [loginLoading, setLoginLoading] = useState(false);
    const [infoMsg, setInfoMsg] = useState("Verify my email");
    const [initialLoading, setInitialLoading] = useState(false);
    const [initialError, setInitialError] = useState('');
    const [isBtnClicked, setIsBtnClicked] = useState(false);
    const [user] = useAuthState(auth);

    const phoneRef = useRef("")
    const err = useRef("");
    const navigate = useNavigate();


    console.log("Entire link from App.tsx inside Auth", linkObj.link_id);

    console.log("Entire link from Redux inside Auth", linkObj.redirect_link);

    console.log("Token inside auth",)
    console.log("link id", linkObj.link_id);
    if (linkObj.link_id) {
        cookies.set("link_id", linkObj.link_id)
    }
    const getPhoneForAuth = async () => {

        const formParams = new URLSearchParams();
        formParams.append('c', 'p');
        console.log("check if id", linkObj.link_id);
        setUserLinkId({id: linkObj.link_id})
        try {
            // const resp = await fetch(`${baseUrlPatientInfoNode}`, {
            const resp = await fetch(`${billingApiUrl}/api/patients/${linkObj.link_id || cookies.get('link_id')}/notoken?` + formParams.toString(), {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "ngrok-skip-browser-warning": "69420",
                    "x-api-key": API_KEY,
                },
            })
            if (resp.ok) {
                const result = await resp.text()
                console.log("result", result)
                setPhone(result)
                //dispatch(updateLinkId({link_id: linkObj.link_id}))
            } else if (resp.status === 401) {
                const result = await resp.text();
                console.log("response result", result);
                if (result === 'Link Expired.') {
                    navigate("/linkexpired");
                } else if (result === 'Process has completed.') {
                    navigate("/stepscomplete")
                } else {
                    navigate("/notauthorized")
                }
            } else {
                navigate("*")
            }

        } catch (error: any) {
            navigate("*");
            console.log(error);
            console.log("This is the error mes", error.message);
        }
    }


    useEffect(() => {
        if (user) {
            console.log("Has user");
            if (!cookies.get('link_id')) {
                console.log("link id in auth from redux", linkObj.link_id)
                console.log("link id in auth from useContext", userLinkId.id);

                console.log("Check if link id", cookies.get('link_id'))
                if (linkObj.link_id) {
                    cookies.set("link_id", linkObj.link_id)
                }
                console.log("STOP and ADD LINK ID")
            }
            navigate("/patientinfo")
        } else if (!user) {
            if (!cookies.get('link_id')) {
                console.log("THIS IS FORBIDDEN")
                navigate("/forbidden");
            } else {
                console.log("MADE IT TO GET Phone Auth")
                //setPhone("650-555-0311")
                getPhoneForAuth()
            }
        }

    }, [user, navigate])


    const sendOtp = async (e: any) => {
        e.preventDefault();
        console.log("Send OTP Button clicked");
        phoneRef.current = phone;
        // if(phoneRef.current.substring(0,1) !== '+') {
        //     phoneRef.current = "+1" + phoneRef.current;
        //     console.log("add +1 to ", phoneRef.current);
        // }
        // else {
        //     console.log("Don't add +1")
        // }
        setLoginLoading(true);
        setIsBtnClicked(true);
        setLoginError('');
        try {
            const recaptcha = new RecaptchaVerifier(auth, "recaptcha", {
                'size': 'invisible',
            })

            console.log("hello before confirmation");
            const confirmation: any = await signInWithPhoneNumber(auth, phoneRef.current, recaptcha)
            setUserInfo(confirmation);
            console.log("confirmation", confirmation);

        } catch (error: any) {
            console.log(error);
            setLoginLoading(false);
            const msg = error.message;
            const index1 = msg.indexOf("(");
            const index2 = msg.indexOf(")");
            const errSmsMsgStr = msg.substring(index1 + 1, index2);
            //setLoginError(error.message);
            setSmsError(errSmsMsgStr);
            console.log("There was an error");
        }
    }

    const verifyOtp = async (e: any) => {
        e.preventDefault();
        try {
            const data: any = await userInfo.confirm(otp)
            console.log("Data", data);

        } catch (error: any) {
            console.log(error);
            const msg = error.message;
            const index1 = msg.indexOf("(");
            const index2 = msg.indexOf(")");
            const errMsgStr = msg.substring(index1 + 1, index2);
            console.log("Message Str", errMsgStr);
            setLoginError(errMsgStr);
            console.log("HELLO ERROR", error.message);
        }
    }

    return (
        <>
            {initialLoading ? (
                <Container className="d-flex justify-content-center">
                    <h2>Loading...</h2>
                </Container>
            ) : (
                <>
                    {initialError !== '' ? (
                        <Error401/>
                    ) : (
                        <>
                            {user ? (
                                <div>Loading</div>
                            ) : (
                                <>
                                    <h1>{t('authPhoneTitle')}</h1>
                                    <Container className="auth-text">
                                        <p className="text-center">{t('authPhoneInstructions')}</p>
                                    </Container>
                                    <Container className="d-flex justify-content-center">
                                        <Container className="auth-page-container">
                                            <Form onSubmit={sendOtp} className="text-center">
                                                <Form.Group className="mb-3" controlId="formBasicPhone">
                                                    <Form.Label>{t('authPhoneLabel')}</Form.Label>
                                                    <Form.Control className="authPhone" disabled
                                                                  value={phone} readOnly
                                                                  placeholder={t('authPhonePlaceholder')}
                                                                  onChange={(e) => setPhone(e.target.value)}></Form.Control>
                                                </Form.Group>

                                                <div className="div-auth-button">
                                                    <Button id="recaptcha" disabled={!phone || smsError || userInfo}
                                                            className="btn-auth" type="submit">
                                                        {/* {infoMsg} */}
                                                        {loginLoading ?
                                                            <span>{t('authPhoneSpanMsg1')}</span>
                                                            :
                                                            <span>{t('authPhoneSpanMsg2')}</span>
                                                        }
                                                    </Button>
                                                </div>
                                            </Form>
                                        </Container>
                                    </Container>
                                    <Container className="sms-error-bar">
                                        {smsError && <Alert className="alert-msg" variant="danger">{smsError}</Alert>}
                                    </Container>
                                    <Container className="banner-msg">
                                        {userInfo &&
                                            <>
                                                <div className="sms-image-container">
                                                    <img src="/assets/smsImage.png" height="125px" width="125px"></img>
                                                </div>
                                                <div className="verify-code-title">
                                                    <h3 style={{width: "250px"}}>{t('enterCodeTitle')}</h3>
                                                </div>
                                                <Container className="code-form-container">
                                                    <Form className="text-center" onSubmit={verifyOtp}>
                                                        <Form.Group className="otp-group-cont"
                                                                    controlId="formBasicPhone">
                                                            <Form.Control className="otp-control"
                                                                          inputMode="numeric"
                                                                          maxLength={6}
                                                                          placeholder={t('authPhoneOtpPlaceHolder')}
                                                                          onChange={(e) => setOtp(e.target.value)}></Form.Control>
                                                        </Form.Group>
                                                        <Container className="btn-otp-cont">
                                                            <Button className="btn-auth"
                                                                    type="submit">{t('authPhoneButton')}</Button>
                                                        </Container>
                                                        <div className="div-auth-button">
                                                            {loginError && <Alert className="alert-msg"
                                                                                  variant="danger">{loginError}</Alert>}
                                                        </div>
                                                    </Form>
                                                </Container>
                                                <Container className="display-norefresh-msg-container">
                                                    <div className="alert alert-primary jumbotron text-center"
                                                         role="alert">
                                                        <p>{t('authPhoneParagraph')}</p>
                                                        <h4>{t('authPhoneNoRefreshPage')}</h4>
                                                    </div>
                                                </Container>
                                            </>
                                        }
                                    </Container>
                                </>
                            )}
                        </>
                    )}
                </>
            )}
        </>
    )
}